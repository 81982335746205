import React from 'react'
import styled from 'styled-components'

// Components
import Circle from 'components/elements/SvgElements/Circle'

// Elements
import Blogik, { BlogConsumer } from 'components/shared/Blogik'
import ButtonTransparentArrow from 'components/elements/Buttons/ButtonTransparentArrow'

const StyledHighlightedProject = styled.section`
  & h2 {
    color: ${(props) => props.theme.color.dark};
    font-size: ${(props) => props.theme.font.size.subheading};
  }
`

const Background = styled.div`
  background: rgb(${(props) => props.theme.color.gradient.contrast.to});
  background: linear-gradient(
    135deg,
    rgba(${(props) => props.theme.color.gradient.contrast.from}, 1) 0%,
    rgba(${(props) => props.theme.color.gradient.contrast.to}, 1) 45%
  );
  border-top-left-radius: 41px;
  border-bottom-left-radius: 41px;
  &:before {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 2000px;
    background-color: ${(props) => props.theme.color.secondary};
  }
`

interface HighlightedProjectProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_HighlightedProject
}

const HighlightedProject: React.FC<HighlightedProjectProps> = ({ fields }) => (
  <div className="py-4">
    <StyledHighlightedProject className="my-5 pt-5 pt-lg-0 position-relative">
      <div
        className="position-absolute w-100 h-100"
        style={{ left: 0, right: 0 }}
      >
        <div className="container-fluid h-100">
          <div className="row h-100 justify-content-end">
            <Background className="col-11 col-lg-9 h-100 position-relative" />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="mt-5">
          <Blogik
            type="projects"
            settings={{
              id: 'highlighted-Project',
              limit: Infinity,
              type: 'projecten',
              postIds: [fields.relation![0]?.databaseId || 0],
            }}
          >
            <BlogConsumer>
              {(context: any) => (
                <div className="row no-gutters justify-content-end">
                  <div className="col-lg-9 col-11 mb-5 mb-md-0">
                    {context.posts[0] && (
                      <div>
                        <Post fields={context.posts[0].node} />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </BlogConsumer>
          </Blogik>
        </div>
      </div>
    </StyledHighlightedProject>
  </div>
)

const StyledPost = styled.div`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${(props) => props.theme.color.secondary};
  }

  & > div {
    @media (min-width: 1320px) {
      max-width: 1100px;
    }
  }

  & p {
    color: ${(props) => props.theme.color.light};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
`

const CircleWrapper = styled.div`
  position: absolute;
  z-index: 4;
  left: -170px;
  @media (max-width: 991px) {
    top: -150px;
    left: -70px;
  }
  @media (max-width: 575px) {
    left: calc(50% - 85px);
  }
  & svg {
    @media (max-width: 991px) {
      max-width: 250px;
    }
    @media (max-width: 767px) {
      max-width: 170px;
    }
  }
`

const InfoWrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.color.light};
`

const Name = styled.p`
  font-size: ${(props) => props.theme.font.size.big};
`

const Quote = styled.p`
  font-size: 40px;
  line-height: 48px;
  font-family: ${(props) => props.theme.font.family.secondary};
  @media (max-width: 767px) {
    font-size: ${(props) => props.theme.font.size.big};
    line-height: 40px;
  }
`

const StyledButton = styled(ButtonTransparentArrow)``

interface PostProps {
  fields: {
    title: string
    projectdetail: {
      info: {
        image: any
        name: string
        position: string
      }
      quote: string
    }
    uri: string
  }
}

const PostContent = styled.div`
  padding-left: 150px;

  @media screen and (max-width: 991px) {
    padding-left: 0;
  }
`

const Post: React.FC<PostProps> = ({ fields }) => {
  const { info } = fields.projectdetail

  return (
    <StyledPost className="position-relative row">
      <div className="px-lg-5 px-4 py-lg-4 py-3">
        <PostContent>
          <InfoWrapper className="d-flex flex-lg-row flex-column align-items-center mt-5 mt-sm-4 pt-sm-0 pt-5">
            <Name className="font-weight-bold mr-3">{info?.name}, </Name>
            <p>{info?.position}</p>
          </InfoWrapper>
          <div className="d-flex align-items-center">
            <CircleWrapper>
              <Circle image={info?.image?.localFile?.publicURL} />
            </CircleWrapper>
            <Quote className="text-white pr-lg-5 pt-3">
              {fields?.projectdetail?.quote}
            </Quote>
          </div>
        </PostContent>

        <div className="text-right mt-3 pr-lg-5">
          <StyledButton to={fields.uri}>
            <span className="text-white">Lees meer</span>
          </StyledButton>
        </div>
      </div>
    </StyledPost>
  )
}

export default HighlightedProject
