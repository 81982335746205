import React from 'react'

function Icon({ image }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="324"
      height="324"
      fill="none"
      viewBox="0 0 324 324"
    >
      <g filter="url(#filter0_d)">
        <circle r="132" fill="#fff" transform="matrix(-1 0 0 1 162 162)" />
        <mask
          id="mask0"
          width="264"
          height="264"
          x="30"
          y="30"
          maskUnits="userSpaceOnUse"
        >
          <circle r="132" fill="#fff" transform="matrix(-1 0 0 1 162 162)" />
        </mask>
        <g mask="url(#mask0)">
          <path
            fill="url(#pattern0)"
            d="M0 0H264V264H0z"
            transform="matrix(-1 0 0 1 295 30)"
          />
        </g>
      </g>
      <circle
        cx="162"
        cy="162"
        r="132"
        stroke="url(#paint0_linear)"
        strokeWidth="5"
      />
      <defs>
        <filter
          id="filter0_d"
          width="324"
          height="324"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="15" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <pattern
          id="pattern0"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="scale(.00333)" xlinkHref="#image0" />
        </pattern>
        <linearGradient
          id="paint0_linear"
          x1="532.13"
          x2="263.291"
          y1="250.22"
          y2="-81.919"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB0045" />
          <stop offset="1" stopColor="#FF8300" />
        </linearGradient>
        <image id="image0" width="300" height="300" xlinkHref={image} />
      </defs>
    </svg>
  )
}

export default Icon
